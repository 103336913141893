import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import AuthGuard from "../middleware/AuthGuard";
import BeforeAuthGuard from "../middleware/BeforeAuthGuard";
import Login from "../pages/auth/Login";

//import Dashboard from '../pages/admin/Dashboard';
//import ListPage from '../pages/admin/ListPage';
import {
  UserForm,
  Dashboard,
  ListPage,
  LocationForm,
  VendorForm,
  RoleForm,
  DesignationForm,
  CompanyForm,
  CompanyLocationForm,
  ShiftForm,
  ReportsList,
  RoleRights,
  ApkConfigurationForm,
  CommonReportsList,
  GateLockReport
} from "../pages/admin/";

import AuthLayout from "../layouts/AuthLayouts";
import AdminLayout from "../layouts/admin/AdminLayout";
import ForgotPassword from "../pages/auth/ForgotPassword";
import {
  LocationColumns,
  UserColumns,
  VendorColumns,
  DesignationColumns,
  CheckpointColumns,
  ShiftColumns,
  CompanyColumns,
  RolesColumns,
  AppColumns,
  // RoleRightsColumns,
  CompanymasterColumns,
  AttendanceReportsColumns,
  TresspasingReportsColumns,
  GetLockReportsColumns,
  SalaryReportsColumns,
} from "../utils/tableColumns";
import {
  UserFilterOption,
  LocationFilterOption,
  DesiginationFilterOption,
  VendorFilterOption,
  CheckpointFilterOption,
  CompanyFilterOption,
  RoleFilterOption,
  ShiftFilterOption,
  CompanymasterFilterOption,
  AttendanceFilterOption,
  TresspasingFilterOption,
  GatelockFilterOption,
  SalaryFilterOption,
} from "../utils/filterOption";
import {
  locationColumnsOrder,
  userColumnsOrder,
  vendorColumnsOrder,
  CompanymasterColumnsOrder,
  CompanyColumnsOrder,
  CheckpointColumnsOrder,
  DesignationColumnsOrder,
  ShiftColumnsOrder,
  AttendanceReportsColumnsOrder,
  RolesColumnsOrder,
  GetLockReportsColumnsOrder,
  TrespassingColumnsOrder,
  SalaryColumnsOrder,
} from "../utils/columnOrder";
import CheckPointForm from "../pages/admin/CheckPointForm";
import LiveLocation from "../pages/admin/LiveLocation";
import {
  AttendanceReportsExportColumnsOrder,
  GetLockReportsExportColumnsOrder,
  keyMappingForAttendanceReports,
  keyMappingForGetLockReports,
  keyMappingForTresspassingReports,
  keyMappingForSalaryReports,
  SalaryReportsExportColumnsOrder,
} from "../utils/ExportColumns";
import TrackLocation from "../pages/admin/TrackLocation";
import SalaryReport from "../pages/admin/SalaryReport";
import ScrollToTop from "../components/ScrollToTop";

const AppRouter = () => (
  <Router>
  <ScrollToTop />
    <Routes>
      {/* <Route path="/" element={React.createElement(AuthLayout, { Login: 'Login' }, <Login />)} /> */}

      <Route element={<BeforeAuthGuard />}>
        <Route
          exact
          path="/"
          element={
            <AuthLayout>
              <Login title="Login" />
            </AuthLayout>
          }
        />
        <Route
          path="/login"
          element={
            <AuthLayout>
              <Login title="Login" />
            </AuthLayout>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <AuthLayout>
              <ForgotPassword title="Forgot Password" />
            </AuthLayout>
          }
        />
      </Route>
      <Route element={<AuthGuard />}>
        <Route
          path="/dashboard"
          element={
            <AdminLayout>
              <Dashboard title="Home" />
            </AdminLayout>
          }
        />
        <Route
          path="/userlist"
          element={
            <AdminLayout>
              <ListPage
                title="User List"
                ComonComponanatList={UserForm}
                endPoint="user"
                dynamiColumns={UserColumns}
                filterOption={UserFilterOption}
                columnOrder={userColumnsOrder}
              />
            </AdminLayout>
          }
        />
        <Route
          path="/locationlist"
          element={
            <AdminLayout>
              <ListPage
                title="Location List"
                ComonComponanatList={LocationForm}
                endPoint="location"
                dynamiColumns={LocationColumns}
                filterOption={LocationFilterOption}
                columnOrder={locationColumnsOrder}
              />
            </AdminLayout>
          }
        />
        <Route
          path="/vendorlist"
          element={
            <AdminLayout>
              <ListPage
                title="Vendor List"
                ComonComponanatList={VendorForm}
                endPoint="vendor"
                dynamiColumns={VendorColumns}
                filterOption={VendorFilterOption}
                columnOrder={vendorColumnsOrder}
              />
            </AdminLayout>
          }
        />
        <Route
          path="/designationlist"
          element={
            <AdminLayout>
              <ListPage
                title="Designation List"
                ComonComponanatList={DesignationForm}
                endPoint="designation"
                dynamiColumns={DesignationColumns}
                filterOption={DesiginationFilterOption}
                columnOrder={DesignationColumnsOrder}
              />
            </AdminLayout>
          }
        />

        <Route
          path="/checkpointlist"
          element={
            <AdminLayout>
              <ListPage
                title="Checkpoint List"
                ComonComponanatList={CheckPointForm}
                endPoint="checkpoint"
                dynamiColumns={CheckpointColumns}
                filterOption={CheckpointFilterOption}
                columnOrder={CheckpointColumnsOrder}
              />
            </AdminLayout>
          }
        />
        <Route
          path="/shiftlist"
          element={
            <AdminLayout>
              <ListPage
                title="Shift List"
                ComonComponanatList={ShiftForm}
                endPoint="getshift"
                dynamiColumns={ShiftColumns}
                filterOption={ShiftFilterOption}
                columnOrder={ShiftColumnsOrder}
              />
            </AdminLayout>
          }
        />
        <Route
          path="/companylist"
          element={
            <AdminLayout>
              <ListPage
                title="Company Location List"
                ComonComponanatList={CompanyLocationForm}
                endPoint="company"
                dynamiColumns={CompanyColumns}
                filterOption={CompanyFilterOption}
                columnOrder={CompanyColumnsOrder}
              />
            </AdminLayout>
          }
        />

        <Route
          path="/companymasterlist"
          element={
            <AdminLayout>
              <ListPage
                title="Company List"
                ComonComponanatList={CompanyForm}
                endPoint="companydata"
                dynamiColumns={CompanymasterColumns}
                filterOption={CompanymasterFilterOption}
                columnOrder={CompanymasterColumnsOrder}
              />
            </AdminLayout>
          }
        />

        <Route
          path="/roleList"
          element={
            <AdminLayout>
              <ListPage
                title="Role List"
                ComonComponanatList={RoleForm}
                endPoint="roles"
                dynamiColumns={RolesColumns}
                filterOption={RoleFilterOption}
                columnOrder={RolesColumnsOrder}
              />
            </AdminLayout>
          }
        />

        <Route
          path="/apkConfiguration"
          element={
            <AdminLayout>
              <ListPage
                title="App Version Configuration"
                ComonComponanatList={ApkConfigurationForm}
                endPoint="appconfigreport"
                // dynamiColumns={RolesColumns}
                // filterOption={RoleFilterOption}
                // columnOrder={RolesColumnsOrder}
                dynamiColumns={AppColumns}
                // filterOption={RoleFilterOption}
                // columnOrder={RolesColumnsOrder}
              />
            </AdminLayout>
          }
        />

        <Route
          path="/attendanceReports"
          element={
            <AdminLayout>
              {/* <ReportsList
                title="Attendance Report List"
                endPoint="attendance"
                dynamiColumns={AttendanceReportsColumns}
                filterOption={AttendanceFilterOption}
                columnOrder={AttendanceReportsColumnsOrder}
                excludeKeys={["Sr. No.", "preview", "map"]}
                exportColumns={AttendanceReportsExportColumnsOrder}
                keyMapping={keyMappingForAttendanceReports}
              /> */}
              <CommonReportsList
                title="Attendance Report List"
                endPoint="attendance"
                dynamiColumns={AttendanceReportsColumns}
                filterOption={AttendanceFilterOption}
                columnOrder={AttendanceReportsColumnsOrder}
                excludeKeys={["Sr. No.", "preview", "map"]}
                exportColumns={AttendanceReportsExportColumnsOrder}
                keyMapping={keyMappingForAttendanceReports}
              />
            </AdminLayout>
          }
        />

        <Route
          path="/getLockReports"
          element={
            <AdminLayout>
              {/* <ReportsList
                title="Gate Lock Report List"
                endPoint="gatelockreport"
                dynamiColumns={GetLockReportsColumns}
                filterOption={GatelockFilterOption}
                columnOrder={GetLockReportsColumnsOrder}
                excludeKeys={["Sr. No.", "preview"]}
                exportColumns={GetLockReportsExportColumnsOrder}
                keyMapping={keyMappingForGetLockReports}
              /> */}              
              <CommonReportsList
                title="Gate Lock Report List"
                endPoint="gatelockreport"
                dynamiColumns={GetLockReportsColumns}
                filterOption={GatelockFilterOption}
                columnOrder={GetLockReportsColumnsOrder}
                excludeKeys={["Sr. No.", "preview"]}
                exportColumns={GetLockReportsExportColumnsOrder}
                keyMapping={keyMappingForGetLockReports}
              />
            </AdminLayout>
          }
        />

        <Route
          path="/liveLocation"
          element={
            <AdminLayout>
              <LiveLocation title="Gate Lock Report List" />
            </AdminLayout>
          }
        />

        <Route
          path="/trackLocation"
          element={
            <AdminLayout>
              <TrackLocation title="Track Location" />
            </AdminLayout>
          }
        />

        <Route
          path="/rolerights"
          element={
            <AdminLayout>
              <RoleRights />
            </AdminLayout>
          }
        />

        <Route
          path="/getTrespassing"
          element={
            <AdminLayout>
              {/* <ReportsList
                title="Trespassing Report List"
                endPoint="getNotificationsAllReport"
                dynamiColumns={TresspasingReportsColumns}
                filterOption={TresspasingFilterOption}
                columnOrder={TrespassingColumnsOrder}
                excludeKeys={["Sr. No.", "preview", "voicenote", "map"]}
                exportColumns={AttendanceReportsExportColumnsOrder}
                keyMapping={keyMappingForTresspassingReports}
              /> */}
              <CommonReportsList
                title="Trespassing Report List"
                endPoint="getNotificationsAllReport"
                dynamiColumns={TresspasingReportsColumns}
                filterOption={TresspasingFilterOption}
                columnOrder={TrespassingColumnsOrder}
                excludeKeys={["Sr. No.", "preview", "voicenote", "map"]}
                exportColumns={AttendanceReportsExportColumnsOrder}
                keyMapping={keyMappingForTresspassingReports}
              />
            </AdminLayout>
          }
        />

        <Route
          path="/getfire"
          element={
            <AdminLayout>
              {/* <ReportsList
                title="Fire Report List"
                endPoint="getNotificationsAllReport"
                dynamiColumns={TresspasingReportsColumns}
                filterOption={TresspasingFilterOption}
                columnOrder={TrespassingColumnsOrder}
                excludeKeys={["Sr. No.", "preview", "voicenote", "map"]}
                exportColumns={AttendanceReportsExportColumnsOrder}
                keyMapping={keyMappingForTresspassingReports}
              />               */}
              <CommonReportsList
                title="Fire Report List"
                endPoint="getNotificationsAllReport"
                dynamiColumns={TresspasingReportsColumns}
                filterOption={TresspasingFilterOption}
                columnOrder={TrespassingColumnsOrder}
                excludeKeys={["Sr. No.", "preview", "voicenote", "map"]}
                exportColumns={AttendanceReportsExportColumnsOrder}
                keyMapping={keyMappingForTresspassingReports}
              />              
            </AdminLayout>
          }
        />

        <Route
          path="/getpanicmode"
          element={
            <AdminLayout>
              {/* <ReportsList
                title="Panic Mode Report List"
                endPoint="getNotificationsAllReport"
                dynamiColumns={TresspasingReportsColumns}
                filterOption={TresspasingFilterOption}
                columnOrder={TrespassingColumnsOrder}
                excludeKeys={["Sr. No.", "preview", "voicenote", "map"]}
                exportColumns={AttendanceReportsExportColumnsOrder}
                keyMapping={keyMappingForTresspassingReports}
              /> */}
              <CommonReportsList
                title="Panic Mode Report List"
                endPoint="getNotificationsAllReport"
                dynamiColumns={TresspasingReportsColumns}
                filterOption={TresspasingFilterOption}
                columnOrder={TrespassingColumnsOrder}
                excludeKeys={["Sr. No.", "preview", "voicenote", "map"]}
                exportColumns={AttendanceReportsExportColumnsOrder}
                keyMapping={keyMappingForTresspassingReports}
              />
            </AdminLayout>
          }
        />

        <Route
          path="/getsalaryreport"
          element={
            <AdminLayout>
              <SalaryReport
                title="Salary Report List"
                endPoint="getsalaryreport"
                dynamiColumns={SalaryReportsColumns}
                filterOption={SalaryFilterOption}
                columnOrder={SalaryColumnsOrder}
                excludeKeys={["Sr. No.", "preview", "voicenote", "map"]}
                exportColumns={SalaryReportsExportColumnsOrder}
                keyMapping={keyMappingForSalaryReports}
              />
            </AdminLayout>
          }
        />        
      </Route>
    </Routes>
  </Router>
);
export default AppRouter;
