export const UserFilterOption = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Guard code",
    value: "guard_code",
  },
  {
    label: "Name",
    value: "username",
  },
  {
    label: "Email",
    value: "email",
  },
  {
    label: "Mobile No",
    value: "mobile_no",
  },
  {
    label: "Date of Joining",
    value: "date_of_joining",
  },
  {
    label: "Role",
    value: "role_name",
  },
  {
    label: "Company",
    value: "company_name",
  },
  {
    label: "Designation",
    value: "designation_name",
  },
  {
    label: "Location",
    value: "location_name",
  },
  {
    label: "Vendor Name",
    value: "vendor_name",
  },
  {
    label: "Shift Name",
    value: "shift_name",
  },
  {
    label: "Status",
    value: 'is_active',
  },
];

export const LocationFilterOption = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Location Name",
    value: 'location_name',
  },
  {
    label: "Status",
    value: "is_active",
  },
  {
    label: "Date",
    value: "date",
  },
];

export const DesiginationFilterOption = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Desigination Name ",
    value: "designation_name",
  },
  {
    label: "Company Name",
    value: "company_name",
  },
  {
    label: "Location Name",
    value: "location_name",
  },
  {
    label: "Vendor Name",
    value: "vendor_name",
  },
  {
    label: "Total Salary",
    value: "designation_salary",
  },
  {
    label: "Status",
    value: "is_active",
  },
];
export const VendorFilterOption = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Name",
    value: "vendor_name",
  },
  {
    label: "Email",
    value: "vendor_email",
  },
  {
    label: "Mobile No.",
    value: "vendor_mobile",
  },
  {
    label: "GST No.",
    value: "vendor_gst_no",
  },
  {
    label: "TAN No.",
    value: 'vendor_tan_no',
  },
  {
    label: "PAN No.",
    value: 'vendor_pan_no',
  },
  {
    label: "EPFO No.",
    value: 'vendor_epfo_no',
  },
  {
    label: "Security Certificate Number",
    value: "vendor_security_certificate_no",
  },
  {
    label: "Status",
    value: "is_active",
  },
];

export const CheckpointFilterOption = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Checkpoint Name",
    value: "checkpoint_name",
  },
  {
    label: "Company Name",
    value: "company_name",
  },
  {
    label: "Location Name",
    value: "location_name",
  },
  {
    label: "Status",
    value: "is_active",
  },
  {
    label: "Created Date",
    value: "date",
  },
];

export const CompanyFilterOption = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Company Name",
    value: "company_name",
  },
  {
    label: "Location Name",
    value: "location_name",
  },
  {
    label: "Status",
    value: "is_active",
  },
  {
    label: "Date",
    value: "date",
  },
];

export const CompanymasterFilterOption = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Company Name",
    value: 'company_name',
  },
  {
    label: "Status",
    value: "is_active",
  },
  {
    label: "Date",
    value: "date",
  },
];

export const RoleFilterOption = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Role Name",
    value: "role_name",
  },
  {
    label: "Status",
    value: "is_active",
  },
  {
    label: "Date",
    value: "created_at",
  },
];

export const ShiftFilterOption = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Company Name",
    value: "company_name",
  },
  {
    label: "Location Name",
    value: "location_name ",
  },
  {
    label: "Shift Name",
    value: "shift_name",
  },
  {
    label: "Start Time",
    value: "start_time",
  },
  {
    label: "End Time",
    value: "end_time",
  },
  {
    label: "Status",
    value: "is_active",
  },
  {
    label: "Date",
    value: "date",
  },
];

export const AttendanceFilterOption = [
  {
    label: "Guard Code",
    value: "guard_code",
  },
  {
    label: "Name",
    value: "username",
  },
  {
    label: "Company Name",
    value: "company_name",
  },
  {
    label: "Location",
    value: "location_name",
  },
  {
    label: "Designation",
    value: "designation_name",
  },
  {
    label: "Date",
    value: "date",
  },
];

export const GatelockFilterOption = [
  {
    label: "Guard Code",
    value: "guard_code",
  },
  {
    label: "Name",
    value: "username",
  },
  {
    label: "Company Name",
    value: "company_name",
  },
  {
    label: "Location",
    value: "location_name",
  },
  {
    label: "Designation",
    value: "designation_name",
  },
  {
    label: "Date",
    value: "date",
  },
];

export const TresspasingFilterOption = [
  {
    label: "Guard Code",
    value: "guard_code",
  },
  {
    label: "Name",
    value: "username",
  },
  {
    label: "Company Name",
    value: "company_name",
  },
  {
    label: "Location",
    value: "location_name",
  },
  {
    label: "Designation",
    value: "designation_name",
  },
  {
    label: "Date",
    value: "date",
  },
];

export const SalaryFilterOption = [
  {
    label: "Guard Code",
    value: "guard_code",
  },
  {
    label: "Name",
    value: "username",
  },
  {
    label: "Company Name",
    value: "company_name",
  },
  {
    label: "Location",
    value: "location_name",
  },
  {
    label: "Designation",
    value: "designation_name",
  },
  {
    label: "Vendor",
    value: "vendor_name",
  },
  {
    label: "Date of joining",
    value: "date_of_joining",
  },
  {
    label: "Present Month-Year",
    value: "month_year",
  },
  {
    label: "Year",
    value: "year",
  },
  {
    label: "Salary",
    value: "salary",
  },
];

// export const SalaryFilterOption= [
//   {
//     label: "Company Name",
//     value: "company_name",
//   },
//   {
//     label: "Location",
//     value: "location_name",
//   },
//   {
//     label: "Vendor",
//     value: "vendor_name",
//   },
//   {
//     label: "Security Guard",
//     value: "security_guard",
//   },
// ];
