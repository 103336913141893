import { useState, useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import debounce from 'lodash.debounce'; // Install lodash.debounce if not already
import { showToast } from "./showToast";
import { set } from "react-hook-form";
import { use } from "react";

const useDataFetching = (fetchDataForPlatform, endPoint, title) => {
	const location = useLocation();

	const [state, setState] = useState({
		data: [],
		isError: false,
		isLoading: false,
		isRefetching: false,
		rowCount: 0,
	});

	const [columnFilters, setColumnFilters] = useState([]);

	const [globalFilter, setGlobalFilter] = useState({
		selectFillter: "",
		search: "",
	});

	const [sorting, setSorting] = useState([]);
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 10,
	});

	// Effect to clear global filter when endPoint changes
	useEffect(() => {
		setGlobalFilter({ selectFillter: "", search: "" });
	}, [endPoint, title]);


	const fetchData = useCallback(debounce(async () => {

		if (state.isLoading || state.isRefetching) return;
		setState((prevData) => ({
			...prevData,
			isLoading: !prevData.data.length,
			isRefetching: prevData.data.length > 0,
		}));
		

		const addParamIfExists = (params, key, value) => {
			if (value) {
				params[key] = value;
			}
		};

		const params = {};

		const validPaths = [
			"/roleList",
			"/shiftlist",
			"/attendanceReports",
			"/getLockReports",
			"/getTrespassing",
			"/getfire",
			"/getpanicmode",
			"/getsalaryreport",
			"/userlist",
			"/companymasterlist",
			"/locationList",
			"/companylist",
			"/vendorlist",
			"/designationlist",
			"/checkpointlist"
		];

		const selectedFilter = validPaths.includes(location?.pathname) ? globalFilter?.selectFillter : "search";

		addParamIfExists(params, selectedFilter, globalFilter?.search);

		
		params.limit = pagination?.pageSize;
		params.page = pagination?.pageIndex + 1;
		if (location?.pathname === "/getTrespassing") {
			params.alert_type = "trespassing";
		} else if (location?.pathname === "/getfire") {
			params.alert_type = "fire";
		} else if (location?.pathname === "/getpanicmode") {
			params.alert_type = "panic mode";
		} else {
			delete params.alert_type;
		}


		

		try {
			const response = await fetchDataForPlatform(params, endPoint);
			const responseData = response?.data?.data || response?.data;
			if ((!responseData.error && !responseData.status) || (responseData?.data !== undefined && responseData?.data !== null && responseData?.totalRecords !== null)) {
				const totalRows = response.data.totalRecords || response.totalRecords;				
				setState((prevData) => ({
					...prevData,
					data: responseData,
					rowCount: totalRows,
					isError: false,
					isLoading: false,
					isRefetching: false,
				}));
			} else {
				// showToast(response?.data?.message, "error")
				setState((prevData) => ({
					...prevData,
					data: [],
					isError: false,
					isLoading: false,
					isRefetching: false,
				}));
			}
		} catch (error) {
			setState((prevData) => ({
				...prevData,
				isError: true,
				isLoading: false,
				isRefetching: false,
			}));
		}
	}, 500), [globalFilter, pagination, fetchDataForPlatform, endPoint]);

	const manualFetchData = useCallback(() => {
		fetchData();
	}, [fetchData]);

	useEffect(() => {
		fetchData();
	}, [fetchData, location.pathname]);

	return {
		state,
		columnFilters,
		setColumnFilters,
		globalFilter,
		setGlobalFilter,
		sorting,
		setSorting,
		pagination,
		setPagination,
		manualFetchData,
	};
};

export default useDataFetching;
